import React, { FC } from "react"
import ImageStickers from "../../../images/image-stickers-mockup.jpg"
import ImageTissuePaper from "../../../images/image-tissue-paper-mockup.jpg"
import ImageBooklet from "../../../images/image-booklet-mockup.jpg"
import Fade from "react-reveal/Fade"
import Block from "./Block"
import Part from "./Part"
import Title from "./Title"
import Description from "./Description"
import Subheading from "./Subheading"
import { Grid, GridItem, GridImage } from "./Grid"

const coBrandingItems = [
  {
    src: ImageStickers,
    alt: "Stickers",
    subheading: "Stickers",
    description:
      "In a hurry, but still want an element of co-branding? Use our standard tissue paper in combination with a co-branded sticker to bring your company logo into your unboxing experience for your team.",
  },
  {
    src: ImageTissuePaper,
    alt: "Tissue paper",
    subheading: "Tissue paper",
    description:
      "Our ripple print tissue paper is iconic and symbolises the impact your order creates. Bring your company logo to this to elevate the unboxing experience. ",
  },
  {
    src: ImageBooklet,
    alt: "Booklets",
    subheading: "Booklets",
    description:
      "Every gift comes with a short booklet with information on Social Supermarket and our brands. Personalise it with your logo, a message or the impact your gifts created to inspire your recipients.",
  },
]

interface Props {
  title: JSX.Element
}

const PersonalizationBlock: FC<Props> = ({ title }): JSX.Element => {
  return (
    <Fade>
      <Block>
        <Part width="800px" padding="0 0 30px 65px" wrapPadding="0 0 25px 0">
          <Title>{title}</Title>
        </Part>
        <Grid>
          {coBrandingItems.map(({ src, alt, subheading, description }) => (
            <GridItem key={subheading}>
              <GridImage src={src} alt={alt} bottomMargin="45px" />
              <Subheading>{subheading}</Subheading>
              <Description margin="20px 0 0 0" tabletMargin="10px 0 0 0">
                {description}
              </Description>
            </GridItem>
          ))}
        </Grid>
      </Block>
    </Fade>
  )
}

export default PersonalizationBlock
